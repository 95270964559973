import {useRedirect} from './hooks/useRedirect';

// this component is only redirecting the user.
// It does not have any UI elements, so it returns null.
// the component has been moved to RideSerachComponent.js and is not used.

export default function RideSearch() {
    useRedirect();
    return null;
}
