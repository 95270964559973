import { useEffect } from 'react';

export function useRedirect() {
    useEffect(() => {
        preventBackNavigation();
        handleRedirect();

        return () => {
            cleanUpEventListeners();
        };
    }, []);
}

function preventBackNavigation() {
    // Replace the current history entry with the same URL to remove previous entries
    window.history.replaceState(null, "", window.location.href);

    // Push multiple history states to make it harder to go back
    for (let i = 0; i < 10; i++) {
        window.history.pushState(null, "", window.location.href);
    }

    // Add another history entry and trap back button navigation
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        alert("Back navigation is disabled!");
        window.history.pushState(null, "", window.location.href);
    };

    // Prevent keyboard shortcuts that allow back navigation
    document.addEventListener('keydown', blockBackNavigationKeys);
}

// Function to block keyboard shortcuts that allow navigating back
function blockBackNavigationKeys(event) {
    if (event.key === "Backspace" || ((event.metaKey || event.ctrlKey) && event.key === "ArrowLeft")) {
        event.preventDefault();
        alert("Navigation disabled");
    }
}

function handleRedirect() {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const searchParams = url.search;

    // Clear session storage before redirect
    sessionStorage.clear();

    if (window.location.pathname === "/search") {
        if (searchParams) {
            window.location.href = `https://app.oneops.flixbus.com/${searchParams}`;
        } else {
            window.location.href = `https://app.oneops.flixbus.com/`;
        }
    }
}

function cleanUpEventListeners() {
    window.removeEventListener("popstate", window.onpopstate);
    document.removeEventListener('keydown', blockBackNavigationKeys);
}
