import React, { useEffect, useState } from 'react';
import { Button } from '@flixbus/honeycomb-react';
import { Icon, IconArrowUp } from '@flixbus/honeycomb-icons-react';
import { useQueryClient } from '@tanstack/react-query';
import SearchResultsPanel from '../../SearchResultsPanel';
import useRideSearch from '../../RideSearch/hooks/useRideSearch';
import {
    COOKIE_SEARCH_PARAMS_KEY,
    COOKIE_SEARCH_RESULT_TOTAL,
} from '../../RideSearch/RideSearchComponent';
import {
    openSearchPanelAction,
    searchPanelShownAction,
} from '../../../DataDog/actions';
import './SearchResultWidget.scss';

const SearchResultWidget = () => {
    const queryClient = useQueryClient();
    const [searchData, setSearchData] = useState([]);

    const [isSearchResultsPanelVisible, setIsSearchResultsPanelVisible] =
        useState(false);
    const togglePanel = () =>
        setIsSearchResultsPanelVisible(!isSearchResultsPanelVisible);

    const searchParams = JSON.parse(
        sessionStorage.getItem(COOKIE_SEARCH_PARAMS_KEY)
    );
    const shownAmount = JSON.parse(
        sessionStorage.getItem(COOKIE_SEARCH_RESULT_TOTAL)
    );

    const { data, refetch, isFetched } = useRideSearch({
        ...searchParams,
        batchSize: shownAmount,
    });

    useEffect(() => {
        const rawSearchData = queryClient.getQueriesData([
            'search',
            searchParams,
        ]);

        if (rawSearchData && rawSearchData[1] && rawSearchData[1].length) {
            setSearchData(
                rawSearchData.reduce((acc, curr) => {
                    if (curr[1]) {
                        return [...acc, ...curr[1].results];
                    }
                    return [...acc];
                }, [])
            );
        } else if (searchParams) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isFetched) {
            setSearchData(data.results);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetched]);

    useEffect(() => {
        if (searchData.length) {
            sendPanelShownAnalytics();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchData]);

    const handleTriggerClick = () => {
        togglePanel();

        openSearchPanelAction({
            resultsShown: searchData.length,
            timestamp: new Date().getTime(),
        });
    };

    const sendPanelShownAnalytics = () => {
        searchPanelShownAction({
            resultsShown: searchData.length,
            timestamp: new Date().getTime(),
        });
    };

    return searchData.length ? (
        <>
            <Button
                extraClasses="search-panel__trigger"
                appearance="tertiary"
                onClick={handleTriggerClick}
            >
                Previous search result ({searchData.length})
                <Icon
                    extraClasses="search-panel__icon"
                    InlineIcon={IconArrowUp}
                />
            </Button>
            <SearchResultsPanel
                rides={searchData}
                togglePanel={togglePanel}
                isActive={isSearchResultsPanelVisible}
            />
        </>
    ) : null;
};

export default SearchResultWidget;
